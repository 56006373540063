.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  max-width: 1200px;
  /* margin: 0 auto; */
}
.container2 {
  display: block;
  /* justify-content: space-between;
  align-items: flex-start;
  padding: 20px; */
}
.filter-box {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-left: 40px;
}
.info-box {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-left: 40px;
}
.product-listt {
  /* width: 10%; */
  background-color: "black";
}

.product-listt img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

/* Media query pour la mise en page sur les petits écrans */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .filter-box {
    width: 90%;
    margin-bottom: 20px;
  }

  .product-listt {
    width: 90%;
  }
}
/******************* Pour les images ******************************/
.image-link {
  display: inline-block;
}
.card-img-top {
  transition: transform 0.3s ease;
}

.image-link:hover .card-img-top {
  transform: scale(1.1);
}
/*******************************************************************/
/* Media query pour la mise en page sur les petits écrans */
/* @media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .filter-box,
  .info-box {
    width: 100%;
    margin-top: 20px; 
  }
} */
