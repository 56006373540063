.body {
  background: #ccc;
  padding: 30px;
  font-size: 0.6em;
}

h6 {
  font-size: 1em;
}

.containerr {
  width: 21cm;
  min-height: 29.7cm;
}

.invoice {
  background: #fff;
  width: 100%;
  padding: 50px;
}

.logoo {
  width: 4cm;
  float: right;
}

.document-type {
  text-align: right;
  color: #444;
}

.conditions {
  font-size: 1em;
  color: #666;
}
.rectangle {
  width: 700px;
  height: 500px;
  background-color: rgb(243, 238, 238);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.bottom-page {
  font-size: 1em;
  text-align: right;
}
.text {
  font-size: 1.1em;
  text-align: right;
  color: "black";
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #406a7c;
  color: white;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
.styleTitre {
  text-align: "left";
}
.animate-up {
  animation-name: slide-up;
  animation-duration: 0.5s;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.additional-content {
  height: "350px";
  transition: height 0.3s ease;
  overflow: hidden;
}
.alert {
  background-color: #3659f4;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
}
.animate-upp {
  height: 100%; /* La hauteur devient la hauteur maximale du parent */
}
