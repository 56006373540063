.section__padding {
  /* padding: 4rem 4rem; */
}
.footer {
  width: 100%;
}
.sb__footer {
  display: flex;
  flex-direction: column;
}
.sb__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
  color: rgb(255, 255, 255);
}
.sb__footer-links_div {
  font-size: 15px;
  line-height: 15px;
  margin: 0.5rem 0;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.sb__footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}

a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.socialmedia {
  display: flex;
  flex-direction: row;
}
.socialmedia img {
  width: 80%;
}
.sb__footer-links_div h4 {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0.9rem;
}
.sb__footer-links_div p {
  font-size: 12px;
  line-height: 15px;
  margin: 0.5rem 0;
  cursor: pointer;
}
.sb__footer-below-links {
  display: flex;
  flex-direction: row;
}
.sb__footer-below-links p {
  font-size: 13px;
  line-height: 15px;
  margin-left: 2rem;
  color: #f1fada;
  font-weight: 600;
}
hr {
  color: white !important;
  width: 100%;
}
/* @media screen and (max-width: 550px) {
  .sb__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb__footer-links div {
    margin: 1rem 0;
  }
  .sb__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb__footer-below {
    flex-direction: column;
  }
  .sb__footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
@media screen and(max-width:400px) {
  .sb__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
} */
