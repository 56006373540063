.image-container {
  overflow: hidden;
  position: relative;
  width: 300px; /* Ajustez selon vos besoins */
  height: 300px; /* Ajustez selon vos besoins */
}

.image-container img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.image-container img.zoomed {
  transform: scale(1.2); /* Ajustez le facteur de zoom selon vos besoins */
}
