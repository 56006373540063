.box {
  width: 90%;
  height: 60%;
  /* border: 5px solid #808080; */
  padding: 20px;
  margin: auto;
  margin-top: 30px;
  margin-left: 20px;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.image-item {
  width: 30%;
  margin-bottom: 20px;
  max-width: 300px;
}

.card {
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image {
  /* width: 100%;
  height: auto; */
  display: block;
  width: 300px;
}

.image-caption {
  text-align: center;
  padding: 10px;
}
/************************************************************************************/
.product-item {
  border: #b4d4ff;
  padding: 10px;
  flex: 0 0 calc(25% - 20px);
  margin: 0 50px 30px 50px;
  border-style: inset;
  border-radius: 12px;
  width: 1200px;
  height: 400px;
  position: relative;
}
.product-item img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.product-list {
  display: flex;
  margin-top: 40px;
  margin-left: 60px;
}

.product-page {
  display: flex;
  flex-wrap: wrap;
}
/******************************** pour le Link ***********************************************/
.image-link {
  display: inline-block;
}
.card-img-top {
  transition: transform 0.3s ease;
}

.image-link:hover .card-img-top {
  transform: scale(1.1);
}
