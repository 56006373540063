.product-list {
  display: flex;
  margin-top: 40px;
  margin-left: 80px;
}

.product-page {
  display: flex;
  flex-wrap: wrap;
}
.product-item {
  border: #b4d4ff;
  padding: 10px;
  /* flex: 0 0 calc(25% - 20px); */
  /* margin: 0 50px 30px 50px; */
  border-style: inset;
  border-radius: 10px;
  width: 600px;
  height: 500px;
  position: relative;
  flex: 0 0 calc(33.33% - 20px); /* Utilisation de flexbox pour une disposition flexible */
  margin: 0 10px 30px 10px;
}
.product-item img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.description {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: #696969;
  /* top: 280px; */
}
/* .image-container img {
  width: 100%;
  height: auto;
} */
.contained-button {
  background-color: #176b87;
  color: white;
  font-size: "1.2rem";
  height: "auto";
  border-radius: 0;
}
.ambiance {
  text-align: center;
  margin-top: 30px;
  margin-left: 40px;
}

.image-grid {
  display: inline-block;
  /* display: grid;  */
}

.row {
  display: flex;
}
.position {
  position: relative;
}
.position2 {
  position: absolute;
  /* background-color: rgb(65, 65, 65); */
  opacity: 0.8;

  margin-left: -1300px;
}
.position3 {
  position: relative;
  /* background-color: rgb(65, 65, 65); */
  opacity: 0.8;
  margin-left: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.position4 {
  position: relative; /* Change la position à relative pour que les éléments enfants soient positionnés par rapport à ce conteneur */
  background-color: rgb(65, 65, 65);
  opacity: 0.8;
  margin-top: 40px;
  /* margin-left: 100px; */
  margin-right: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.position5 {
  position: relative;
  /* background-color: rgb(65, 65, 65); */
  opacity: 0.8;
  margin-top: 40px;
  margin-left: -700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.position6 {
  position: relative;
  /* background-color: rgb(65, 65, 65); */
  opacity: 0.8;
  margin-top: 40px;
  margin-left: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.image {
  /* width: 500px; */
  /* margin: 5px; */
  opacity: 0.8;
  /* margin-left: 20px; */
}
.imageComptoire {
  width: 450px;
}
/* .image {
  width: 400px;
  margin: 5px;
  opacity: 0.8;
} */

button {
  background-color: #2196f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0d47a1;
}
.blurred-image {
  width: 450px;
  height: 350px;
}
.photo {
  height: 350px;
  width: 750px;
  /* margin-right: 300px; */
}
.photoBois {
  height: 320px;
  width: 450px;
  /* margin-right: 300px; */
}

/*************Pour le text dans l'image *****************/
.text-and-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #000000;
  opacity: 1;
}

.text-and-button h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.text-and-button p {
  font-size: 16px;
  margin-bottom: 20px;
}

.text-and-button button {
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.text-and-button button:hover {
  background-color: #0d47a1;
}
.image-link {
  display: inline-block;
}
.card-img-top {
  transition: transform 0.3s ease;
}

.image-link:hover .card-img-top {
  transform: scale(1.05);
}
/********************************************************************/
body {
  background-color: white;
}

#image1 {
  height: 350px;
  /* width: 750px; */
  background-image: url("../../assets/frigidaire.jpg");
  background-repeat: no-repeat;
  background-size: 250px 250px;
  object-fit: contain;
  position: relative;
}
#image2 {
  height: 350px;
  width: 450px;
  background-image: url("../../assets/citerne.jpg");
  background-repeat: no-repeat;
  background-size: 250px 250px;
  object-fit: contain;
  position: relative;
}
#black-screen {
  height: 250px;
  width: 250px;
  background-color: rgb(100, 96, 96);
  opacity: 0.4;
  transition: 0.3s;
}

#black-screen:hover {
  opacity: 0;
}

#image:hover > #button {
  color: white;
  font-weight: bold;
  background-color: black;
  text-decoration: none;
  border: 2px solid black;
}

#text {
  color: white;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#button {
  color: black;
  width: 120px;
  height: 32px;
  position: absolute;
  font-weight: bold;
  background-color: white;
  text-decoration: none;
  border: 1.5px solid black;
  border-radius: 5px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/************************* Loading*********************************************/
.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}
.loader::after {
  content: "";
  width: 192px;
  height: 4.8px;
  background: #5c6aa6;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
@media screen and (max-width: 480px) {
  .product-item {
    flex: 0 0 calc(50% - 20px);
  }
}
@media screen and (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
