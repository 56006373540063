.textUser {
  font-size: 30px;
  font-weight: bold;
  color: blue;
}
.box-centerUser {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  width: 1110px;
  height: 600px;
  margin-top: 3%;
}
