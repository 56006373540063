.sidebar-items {
  margin-top: 20px;
}
.sidebar-title {
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 20px;
  margin-left: 20px;
}
.sidebar-label-container {
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sidebar-label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 20px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}
.sidebar-label-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.sidebar-label-container input:checked ~ .checkmark {
  background-color: #2196f3;
}
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
.sidebar-label-container input:checked ~ .checkmark::after {
  display: block;
}
.sidebar-label-container .checkmark::after {
  top: 6.4px;
  left: 6.4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}
.line {
  margin-top: 3rem;
  border-color: #f7f7f7;
}
