.body {
  background: #ccc;
  padding: 30px;
  font-size: 0.6em;
}

h6 {
  font-size: 1em;
}

.containerr {
  width: 21cm;
  min-height: 29.7cm;
}

.invoice {
  background: #fff;
  width: 100%;
  padding: 50px;
}

.logoo {
  width: 4cm;
  /* float: right; */
}

.document-type {
  text-align: right;
  color: #444;
}

.conditions {
  font-size: 1em;
  color: #666;
}

.bottom-page {
  font-size: 1em;
  text-align: right;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #406a7c;
  color: white;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
.styleTitre{
  text-align: 'left';
}