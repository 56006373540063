/* Styles CSS pour la modal */
.containerShopping {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dialog-title {
  /* background-color: #eef5ff; */
  color: #176b87;
}

.paper-container {
  margin-top: 20px;
  padding: 10px;
}

.icon-button {
  padding: 10px;
}

.input-base {
  width: 80%;
}

.add-command-button {
  margin-top: 10px;
}

/* Styles CSS pour la table */
.table-container {
  margin-top: 20px;
}

.table-header {
  background-color: #f8f0df;
  color: #fff;
}

.table-row:nth-of-type(even) {
  background-color: #f2f2f2;
}

.table-row:hover {
  background-color: #ddd;
}
