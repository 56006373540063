.box {
  padding: 16px;
  text-align: center;
  color: #333;
}
.container {
  /* width: 103.5%; */
  max-width: 100%;
  margin: 0 auto;
  /* padding: 0 16px; */
  padding: 0;
}
.coloredBox1 {
  padding: 16px;
  text-align: center;
  background-color: #b5c0d0;
}
.coloredBox2 {
  padding: 16px;
  text-align: center;
  background-color: #ccd3ca;
}
.coloredBox3 {
  padding: 16px;
  text-align: center;
  background-color: #f5e8dd;
}
.coloredBox4 {
  padding: 16px;
  text-align: center;
  background-color: #eed3d9;
}
